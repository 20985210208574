import {AiOutlineWhatsApp} from 'react-icons/ai';
import Link from 'next/link';

const Whatsapp = ()=>{
    return <div className="whatsapp">
        <Link href="https://wa.me/525580980257">
            <a target="_blank" rel="nofollow noopener noreferrer">
                <AiOutlineWhatsApp/>
            </a>
        </Link>
    </div>
};


export default Whatsapp;