import Slider from "react-slick";
import { useState } from 'react';
import {FaAngleRight} from 'react-icons/fa';
import Link from 'next/link';
import classNames from 'classnames';
import validator from 'validator';

const Stage = ({data})=>{
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    const slides = ()=>{
        return data.map(i=>{
            let url = (validator.isURL(i.link)) ? i.link : '';
            return(
                <div className="stageItem" key={i.id}>
                    <div className={classNames('stageItemContainer', { 'stageItemContainer-black': !i.clase })} style ={{backgroundImage: "url('"+i.url+"')", backgroundColor: i.color }}>
                        { (validator.isURL(i.link)) ? (
                            <Link href={url}>
                                <a className="stageWrapper" target="_blank">
                                    <div>
                                        <h3 className="stageTitle">{i.title}</h3>
                                        <p className="stageDescription">{i.description}</p>
                                        { validator.isURL(i.link) && <span className="stageButton">Ver más <FaAngleRight/> </span>  }
                                    </div>
                                </a>
                            </Link>
                        ) : (
                                <section className="stageWrapper">
                                    <div>
                                        <h3 className="stageTitle">{i.title}</h3>
                                        <p className="stageDescription">{i.description}</p>
                                        { validator.isURL(i.link) && <span className="stageButton">Ver más <FaAngleRight/> </span>  }
                                    </div>
                                </section>
                        ) }

                    </div>
                </div>
            )
        })
    };
    const thumbs = ()=>{
        return data.map(i=>{
            return(
                <div className="stageThumb" key={i.id}>
                    <div className="stageThumbContainer" style ={{backgroundImage: "url('"+i.url+"')"}}>
                        <h5>{i.title}</h5>
                    </div>
                </div>
            )
        })
    };
    return (
        <div className="marginBottom">
            <Slider asNavFor={nav2}
                    ref={(slider1) => setNav1(slider1)}
                    autoplay={true}
                    arrows={false}
            >
                {slides()}
            </Slider>
            <Slider
                asNavFor={nav1}
                ref={(slider2) => setNav2(slider2)}
                slidesToShow={6}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                arrows={false}
                responsive={[
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1
                    }
                },{
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 4
                        }
                    }
                ]}
            >
                {thumbs()}
            </Slider>
        </div>
    );
};

export default Stage;
