import Stage from '../components/stage';
import Header from '../components/header';
import Newsletter from "../components/newsletter";
import Footer  from '../components/footer';
import Highlight from '../components/highlight';
import fetch from 'isomorphic-fetch';
import config from '../config.json';
import Static from '../config-static.json';
import Head from 'next/head';
import Whatsapp from "../components/whatsapp";
import {MdNavigateNext} from 'react-icons/md';
import Message from "../components/message";
import { useRouter } from 'next/router';

const Home = (props)=>{
    const { asPath } = useRouter();
    return (
        <>
            <Head>
                <title>Arreglos Florales a Domicilio en CDMX y Edomex | Florería Detalles y Caprichos.</title>
                <meta property="fb:app_id" content="932906523399540"/>
                <meta name="robots" content="index, follow"/>
                <meta name="description" content={Static.seo.description}/>
                <meta property="og:description" content={Static.seo.description}/>
                <meta name="keywords" content={Static.seo.keywords}/>
                <link rel="canonical" href={`${Static.domain}${asPath}`}/>
                <meta name="publisher" content={Static.seo.publisher}/>
            </Head>
            <Message/>
            <Header menu={props.menu}/>
            <Stage data={props.stage}/>
            <Highlight data={props.highlight}/>
            <div className="dycMoreProducts">
                <a href="/floreria-cdmx-edomex">Ver el catalogo completo
                    <MdNavigateNext />
                </a>
            </div>
            <Newsletter/>
            <Footer/>
            <Whatsapp/>
        </>
    )
};

export async function getServerSideProps(ctx){
    const res = await fetch(`${config.api.uri}/v1/general/stage?apikey=${config.api.apikey}`);
    const resHigh = await fetch(`${config.api.uri}/v1/product/highlight/list?apikey=${config.api.apikey}&partner=1`); // TODO: set partner id dynamic
    const resJSON = await res.json();
    const resJSONHigh = await resHigh.json();
    const resMenu = await fetch(`${config.api.uri}/v1/cat/menu/1?apikey=${config.api.apikey}`);
    const resJSONMenu = await resMenu.json();
    return {
        props: {
            stage: resJSON,
            highlight: resJSONHigh,
            menu: resJSONMenu
        }
    }
}

export default Home;
