import hexToRgb from 'hex-to-rgb';
import moment from "moment-timezone";
import configStatic from "../config-static.json";

const UnitMinimum = (props)=>{
    const hx = (props.data?.hexadecimal) ? hexToRgb(props.data.hexadecimal) : '';
    const css = (props.data?.hexadecimal) ? `
    #${props.data.slug} .unidadImage:before{
        background-color: rgba(${hx[0]},${hx[1]},${hx[2]},.5);
    }
    #${props.data.slug} .unidadDiscount{
        background: ${props.data.hexadecimal};
    }
    #${props.data.slug} .unidadImage:after{
        border-color: ${props.data.hexadecimal};
    }
    #${props.data.slug} .unidadPrice{
        color: ${props.data.hexadecimal};
    }` : '';

    const getDiscount = ()=>{
        if(props.data?.price?.precio_up){
            const {precio, precio_up} = props.data.price;
            const p = Math.round(((precio_up*100)/precio)-100);
            return <div className="unidadDiscount">-{p}%</div>;
        }
    }
    const labelFor14or10 = ()=>{
        const {data} = props;
        const today = moment();
        const a = configStatic.scheduleLabels;
        const v = (data.available_may10) ? "available_may10" : ( data.available_feb14 ? "available_feb14" : false ); // TODO: solo soportamos una variable, no podriamos tener mayo y febrero por producto
        if(v && !props.data.disabled){
            const staticv = a.filter((i)=> i.key === v).shift();
            if(today.isAfter(staticv.date_start) && today.isBefore(staticv.date_end)){
                return <span className="unidadAvailable">{staticv.label}</span>
            }
            return false;
        }else{
            return false;
        }
    }

    return(
        <div className="unidad" id={props.data.slug} data-valor={labelFor14or10()}>
            <style>{css}</style>
            <Link href={`/producto/${props.data.uri}`}>
                <a>
                    {labelFor14or10()}
                    { ( !labelFor14or10() && (props.data.isnew && !props.data.disabled)) && <div className="unidadNew">Nuevo producto</div> }
                    { props.data.disabled && <div className="unidadExhausted">Producto Agotado</div> }
                    {getDiscount()}
                    <div className="unidadImage">
                        <img src={props?.data?.fotos[0]} alt={props.data.nombre}/>
                    </div>
                    <div className="unidadInfo">
                        <h3>{props.data.nombre}</h3>
                        <div>{props.data.descripcion}</div>
                        { props?.data?.price?.precio && <span className="unidadPrice">{props.data.price.precio}</span> }
                    </div>
                </a>
            </Link>
        </div>)
};

import Link from 'next/link';

export default UnitMinimum;
