import UnitMinimum from './unit-minimum';
import Masonry from 'react-masonry-css';

const highlight = ({data})=>{

    const getItems = ()=>{
        return data.map(i=>{
            return <UnitMinimum data={i} key={i.id_producto}/>;
        });
    }

    return <section className="container marginBottom">
        <h1 className="seoTitle">Flores a domicilio en la ciudad de México</h1>
        <h2 className="seoDescription marginBottom">Florería en línea, arreglos florales a domicilio y entrega el mismo día. Aquí puedes encontrar arreglos florales, frutales y ramos de flores perfectos para cada ocasión.</h2>
        <h3 className="title">Los más enviados</h3>
        <Masonry
            breakpointCols={{
                default: 3,
                1200: 3,
                942: 2,
                622: 1
            }}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            {getItems()}
        </Masonry>
    </section>;
};

export default highlight;
